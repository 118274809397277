// 新闻中心
const news = [
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/News'),
  },
  // 新闻详情页
  {
    path: '/news/newsDetail/:id',
    component: () => import('@/views/News/newsDetail'),
  },
]
export default news
