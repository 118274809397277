const productKey = 'aoron_ow_productId'
const tokenKey = 'aoron_ow_token'

export function setPrId(value) {
  localStorage.setItem(productKey, value)
}
export function getPrId() {
  return localStorage.getItem(productKey)
}
export function removePrid() {
  localStorage.removeItem(productKey)
}
export function setToken(value) {
  localStorage.setItem(tokenKey, value)
}
export function getToken() {
  return localStorage.getItem(tokenKey)
}
export function removeToken() {
  localStorage.removeItem(tokenKey)
}

export function px2rem(px) {
  if (/%/gi.test(px)) {
    // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  } else {
    return parseFloat(px) / 192 + 'rem' // 这里的37.5，和rootValue值对应
  }
}
