<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style scoped>
/* 去除高德地图左下角水印 */
:deep() .amap-logo {
  display: none !important;
}
:deep() .amap-copyright {
  display: none !important;
}
</style>
