// 加入奥荣
const jionUs = [
  {
    path: '/jionUs',
    name: 'jionUs',
    component: () => import('@/views/JionUs'),
    redirect: '/jionUs/recuitment',
    children: [
      // 招聘信息
      {
        path: 'recuitment',
        name: 'recuitment',
        component: () => import('@/views/JionUs/recuitment'),
      },
      // 招商加盟
      {
        path: 'merchants',
        name: 'merchants',
        component: () => import('@/views/JionUs/merchants'),
      },
      // 联系我们
      {
        path: 'contactUs',
        name: 'contactUs',
        component: () => import('@/views/JionUs/contactUs'),
      },
    ],
  },
  // 相册
  {
    path: '/jionUs/album/:id',
    component: () => import('@/views/JionUs/album'),
  },
]
export default jionUs
