// 关于奥荣
const about = {
  path: '/about',
  name: 'about',
  component: () => import('@/views/About/index'),
  redirect: '/about/companyProfile',
  children: [
    // 集团简介
    {
      path: 'companyProfile',
      name: 'companyProfile',
      component: () => import('@/views/About/companyProfile'),
    },
    // 奥荣文化
    {
      path: 'corporateCulture',
      name: 'corporateCulture',
      component: () => import('@/views/About/corporateCulture'),
    },
    // 发展历程
    {
      path: 'developmentHistory',
      name: 'developmentHistory',
      component: () => import('@/views/About/developmentHistory'),
    },
  ],
}
export default about
