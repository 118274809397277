// 解决方案
const solution = [
  {
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/Solution'),
    redirect: '/solution/oneStopSolution',
    children: [
      // 一站式解决方案
      {
        path: 'oneStopSolution',
        name: 'oneStopSolution',
        component: () => import('@/views/Solution/oneStopSolution'),
      },
      // 合作伙伴
      {
        path: 'partners',
        name: 'partners',
        component: () => import('@/views/Solution/partners'),
      },
    ],
  },
  {
    path: '/solution/solutionDetail',
    name: 'solutionDetail',
    component: () => import('@/views/Solution/solutionDetail'),
  },
]
export default solution
